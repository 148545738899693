import React from "react";
import { graphql } from "gatsby";
import { Contact, PageTemplateWrapper } from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import "../../styles/main.less";

const Page = ({ data }) => {
    const { copy, site } = data;
    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "education-secondary",
        locale: site.siteMetadata.lang,
        page: "cn-education-secondary-contact"
    });
    return (
        <PageTemplateWrapper config={config}>
            <Contact copyData={copy.data} domain={site.siteMetadata.legacy.domain} vertical="hed" />
        </PageTemplateWrapper>
    );
};

export default Page;

export const pageQuery = graphql`
    query {
        copy: airtable(data: { title: { eq: "cn-copy" } }) {
            ...PagesCopyFragment
        }
        site {
            ...SiteFragment
        }
    }
`;
